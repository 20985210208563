import WordCloud from "react-d3-cloud";
import { Avatar, Button, Card, Col, Divider, Image, Popover, Row } from "antd";
import { GlobalOutlined, GithubOutlined } from "@ant-design/icons";
import coding from "./imgs/negative-coding.jpg";
import me from "./imgs/me.jpg";
import lep from "./imgs/lep.png";
import phunky from "./imgs/phunky.png";
import oil from "./imgs/oil.jpg";
import swap from "./imgs/swap.png";
import mp from "./imgs/mp.png";
import ethchess from "./imgs/ethchess.png";
import gptdodah from "./imgs/gptdodah.png";
import gpttutorme from "./imgs/gpttutorme.png";
import "./App.css";

const data = [
  { text: "Solidity", value: 10000009 },
  { text: "C++", value: 120000 },
  { text: "C", value: 100000 },
  { text: "Javascript", value: 800000 },
  { text: "Rust", value: 400000 },
  { text: "React", value: 2000000 },
  { text: "React-Native", value: 20000 },
  { text: "Yul", value: 20000 },
  { text: "Node", value: 1000000 },
  { text: "Web3", value: 200000 },
  { text: "Typescript", value: 800000 },
  { text: "Python", value: 1000000 },
  { text: "GraphQL", value: 10000 },
  { text: "Assembly-Script", value: 1000 },
  { text: "Docker", value: 200 },
  { text: "Kubernetes", value: 800 },
  { text: "Cryptography", value: 1000 },
  { text: "Cyber-Security", value: 1000 },
  { text: "Machine Learning", value: 100 },
  { text: "SQL", value: 1000 },
  { text: "HTML/CSS", value: 1000 },
  { text: "EVM", value: 100000 },
  { text: "WebSockets", value: 1000 },
  { text: "P2P Networking", value: 1000 },
  { text: "Cloud Networking", value: 100 },
  { text: "AWS", value: 10000 },
];

function App() {
  return (
    <div className="App">
      <div
        style={{
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
          display: "flex",
        }}
      >
        <Image width="80%" preview={false} src={coding} />
      </div>
      <div className="Section1">
        <Row>
          <Col flex="auto">
            <h1>Jeremiah O. Nolet</h1>
            <p>Software Developer</p>
            <GlobalOutlined />
            <p>Florida, USA</p>
          </Col>
          <Col flex="auto">
            <Avatar size="large" src={me} style={{ marginBottom: 10 }} />
            <br />
            <Popover
              content={
                <div>
                  <p>starkeyjon@protonmail.com</p>
                  <a
                    href={
                      "https://www.linkedin.com/in/jeremiah-nolet-332613191/"
                    }
                  >
                    LinkedIn Profile
                  </a>
                </div>
              }
              title="Contact Info"
              trigger="click"
            >
              <Button>Contact Me</Button>
            </Popover>
            <div style={{ marginTop: 20 }}>
              <a href="https://github.com/StarKeyJON">
                <GithubOutlined /> Github
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <div className="App-body">
        <Row>
          <Col flex="auto">
            <Card style={{ margin: "10%" }}>
              <p>
                Friendly provider of deep programming knowledge and invaluable
                final products.
                <br /> Proud team player focused on achieving project objectives
                with speed and accuracy.
                <br />
                Forward-thinking Software Engineer with background working
                productively in dynamic environments.
                <br /> Fluent in common major and minor programming languages, proficient in team management methodologies, wel versed in cloud infrastructure experience, excels in software design and development, competent.
                <p>Currently holding 2 patents for NBCUniversal in communications systems and advanced software architecture for multi-media applications.</p>
              </p>
            </Card>
            <WordCloud
              data={data}
              // width={500}
              // height={500}
              font="Times"
              fontStyle="italic"
              fontWeight="bold"
              fontSize={(word) => Math.log2(word.value) * 3}
              spiral="rectangular"
              rotate={(word) => word.value % 360}
              padding={5}
              random={Math.random}
            />
          </Col>
        </Row>
      </div>
      <div className="App-body-3" style={{ marginTop: "100px" }}>
        <h1 style={{ marginTop: "20px" }}>
          Currently Working on in my free time:
        </h1>
        <h2>GPT-Tutor.me</h2>
        <p>GPT-Tutor.me is an AI powered tutor application allowing users to engage in 'fuzzy searches' about unknown topics.</p>
        <p>Create new idea categories, generate topics related to a given category, request a curated level of response for explanation style, readability level and length, and visualize the information using text-to-image pipelines.</p>
        <a href="https://gpt-tutor.me"
          target="_blank"
          rel="noopener noreferrer">
            <Image preview={false} src={gpttutorme} />
          </a>
          <br />
        <div style={{ marginTop: 50, marginLeft: 25 }}>
          <p>Former Projects</p>
        </div>
        <h2>GPT-do-dah!</h2>
        <p>
          GPT-do-dah is a "Privacy-Centric Project Collaboration with
          Cryptography, ZK Systems, and Tokenized Rewards for the Web3
          Community".
        </p>
        <p>
          You can find the current website and more information here:{" "}
          <a href="https://gptdodah.com">gptdodah.com</a>
        </p>
        <a
          href="https://gptdodah.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image preview={false} src={gptdodah} />
        </a>
        <p>
          GPT-Do-Dah is a powerful and user-friendly web application that allows
          users to create detailed project MVP's by leveraging the power of
          GPT(Generative pre-trained transformer) NLP(natural language
          processing) models and advanced project management concepts. The
          application is designed with a modern frontend for seamless user
          interaction using Next.js with it's (
          <a href="https://nextjs.org/blog/next-13">now stable!</a>) app
          directory, a scalable backend for handling multiple user requests, and
          a robust websocket server for real-time communication between the
          frontend and backend instances. The GPT-Do-Dah project is organized
          into eight main directories and a main Yarn workspaces:
        </p>
        <ul>
          <li>
            - backend: This directory contains the GPT-Do-Dah backend, which is
            responsible for processing user requests, spawning new Docker
            instances, and interacting with the GPT-AI models. It includes source
            code files, Docker configurations, and build-related files.
          </li>
          <li>
            - common: This directory helps to share files and configurations
            between the various workspace directories.
          </li>
          <li>
            - devops: This directory contains the deployment configuration and
            scripts for deploying the application using Kubernetes. It includes
            the main Kubernetes deployment YAML file, as well as README files
            with deployment instructions.
          </li>
          <li>
            - frontend: This directory contains the Next.js frontend for the
            application. It includes source code files for components, hooks,
            interfaces, and utility functions, as well as build and
            configuration files.
          </li>
          <li>
            - server: This directory contains the websocket server for managing
            communication between the frontend and the backend instances. It
            includes the source code for the websocket server, along with the
            Dockerfile and other build-related files.
          </li>
          <li>
            - services: This directory contains Graph and IPFS Docker container
            instances.
          </li>
          <li>
            - solidity: This directory contains the Hardhat installation of the
            GPT-do-dah smart contracts, using Mocha for unit testing.
          </li>
          <li>
            - subgraoh: This directory contains the sub-graph build and
            deployment files for the solidity smart contracts{" "}
            <a href="https://thegraph.com/">The Graph</a> network decentralized
            indexer.
          </li>
        </ul>
        ## Key Features
        <ul>
          <li>- Multi-modal and multi-model AI integration</li>
          <li>
            - Scalable backend with support for multiple instances per user
          </li>
          <li>
            - Docker-based architecture for easy deployment and management
          </li>
          <li>
            - Real-time communication between frontend and backend instances
            using websockets
          </li>
          <li>- Modern, user-friendly frontend built with Next.js</li>
          <li>- Kubernetes-based deployment for easy scaling and management</li>
        </ul>
      </div>
      <div className="App-body-3">
        <div style={{ marginTop: 25 }}>
          <h2>ETH-Chess!</h2>
          <p>
            Find my working github branch of the project here:{" "}
            <a href="https://github.com/StarKeyJON/EthChess">
              Go to github.com
            </a>
          </p>
          <p>
            A chess application with custom solidity smart contracts allowing
            for decentralized chess match wagers and peer dispute resolutions.
          </p>
          <p>
            Find the current live test stage of the app and more info here:{" "}
            <a href="https://ethchess.com">ETHChess</a>
          </p>
          <a href="https://ethchess.com">
            <Image preview={false} src={ethchess} />
          </a>
        </div>
        <div style={{ marginTop: 25 }}>
          <h2 style={{ color: "white", fontStyle: "italic" }}>
            PharOut Market & Media
          </h2>
          <a
            href="https://kindly-care.surge.sh/#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              preview={false}
              src="https://pharout_labs_pinata.mypinata.cloud/ipfs/QmXZWRFzbmSERiVrEGfNiDhF7nu6CzPrC2kurZUv3479eB"
            />
            <h3 style={{ color: "white" }}>
              A fully decentralized Eth native NFT Marketplace & Media dApp with
              a cryptographically secured backend distributed between the users.
            </h3>
            <p style={{ color: "white" }}>
              Custom solidity contracts(9 proxy contract suite!), GraphQL
              indexer, p2p distributed cyber-security backend.
            </p>
          </a>
          <a
            href="https://phar-out-labs.gitbook.io/pharout-market-guide/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read through the Market Guide I wrote here!
          </a>
          <h3 style={{ color: "white", fontStyle: "italic" }}>
            Below is a diagram of the custom solidity contracts written for the
            marketplace.
          </h3>
          <Image preview={false} src={mp} />
        </div>
        <p style={{ fontSize: "24px" }}>
          The CryptoPhunk community and I went into a partnership agreement
          where they would control the Marketplace Rewards Treasury and
          Multi-Sig in exchange for funding the final build stage.
          <br />
          They placed users in dominant control of the Marketplace Dev multi-sig
          and work had began to refine the front-end with Treasury funds passed
          in the proposal.
          <br />
          Prominant community members publicly smeared one of the founders of
          Ethereum and I had a disagreement with how that image came off since
          we were now in a different relationship.
          <br />
          The community was polarized around vocal and toxic members who were
          being sued by BAYC for trademark infringement and using the community
          as a platform.
          <br />
          Since they had control of the marketplace I designed and built by hand
          and spent over $100,000.00 on, while spending countless 20+ hrs on the
          project to build the whole thing for over 9 months,
          <br />I deceided it was for my best interest of health and sanity to
          part ways and relinquish control to them as they see fit.
          <br /> I am no longer a part of the CryptoPhunk community, below are
          some projects I have built while with them, including winning the
          first CryptoPhunks Hackathon with{" "}
          <a href="phunkfinder.com">PhunkFinder.com !</a>
        </p>
        <Divider />
        <div style={{ marginTop: 150 }}>
          <h2 style={{ color: "white", fontStyle: "italic" }}>
            PharOut Galaxy Swap!
          </h2>
          <a
            href="https://pharout-swap.surge.sh/#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image preview={false} src={swap} />
            <h3 style={{ color: "white" }}>
              A fully decentralized multi-chain crypto trading platform with
              custom trading algorithms and charting features.
            </h3>
            <p style={{ color: "white" }}>
              Access the liquidity of CEX/DEX trading pairs through Orion
              Protocol.
              <br />
              Just connect your crypto wallet and trade!
            </p>
          </a>
        </div>
        <Divider />
        <div style={{ marginTop: 50 }}>
          <h2 style={{ color: "white", fontStyle: "italic" }}>
            LamboLiquidity
          </h2>
          <a
            href="http://lamboliquidity.xyz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image preview={false} src={lep} />
            <h3 style={{ color: "white" }}>
              A custom Eth native LEP NFT collection marketplace operating
              through proxy contracts and the OpenSea MP.
            </h3>
            <p style={{ color: "white" }}>
              Custom solidity contracts, GraphQL indexer, custom ordered theme
              and styling.
            </p>
          </a>
        </div>
        <Divider />
        <div style={{ marginTop: 50 }}>
          <h2 style={{ color: "white", fontStyle: "italic" }}>PhunkyPhresh</h2>
          <a
            href="https://parallel-apparel.surge.sh/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image preview={false} src={phunky} />
            <h3 style={{ color: "white" }}>
              A custom Eth native NFT clothing marketplace for the CryptoPhunk
              community.
            </h3>
            <p style={{ color: "white" }}>
              Custom solidity contracts, GraphQL indexer, custom ordered theme
              and styling, p2p secured private orders.
            </p>
          </a>
        </div>
      </div>
      <header className="App-header">
        <p>Email for more.</p>
        <h2 style={{ color: "white" }}>email@jonthedev.com</h2>
        <div>
          <Image width="40%" src={oil} preview={false} />
        </div>
      </header>
    </div>
  );
}

export default App;
